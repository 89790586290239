import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import Sections from "@/components/sections"
import Seo from "@/components/seo"
import { useFormik } from "formik";
import axios from "axios"
import { Button, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Select, MenuItem, FormHelperText } from '@mui/material';
import QuickCheckForm from "@/components/sections/devops-quickcheck-form"
import BreadcrumbTitle from "@/components/elements/breadcrumb-title"
import StylisedText from "@/components/elements/stylised-text"
import ButtonLink from "@/components/elements/button-link"

const DynamicPage = ({ data, pageContext }) => {

    const [percentage, setPercentage] = React.useState(0);
    const [error, setError] = React.useState(false);
    const [errorLeadTime, setErrorLeadTime] = React.useState(false);
    const [errorDeploy, setErrorDeploy] = React.useState(false);
    const [errorTTR, setErrorTTR] = React.useState(false);
    const [errorChangeFail, setErrorChangeFail] = React.useState(false);
    const [helperText, setHelperText] = React.useState('Required');


    const windowCheck = () => {
        return typeof window !== `undefined` ? true : false
    }


    const formik = useFormik({
        initialValues: {
            firstname: "",
            email: "",
            phone: "",
            leadtime: "",
            deployfreq: "",
            chgfail: "",
            ttr: "",
            industry: "none"
        },
        onSubmit: (values) => {
            // console.log(values)
            submitHubspotForm(values);
        },
    });

    const leadtimeValue = (values) => {
        switch (values.leadtime) {
            case "1":
                return "More than six months";
            case "2":
                return "One to six months";
            case "3":
                return "One week to one month";
            case "4":
                return "One day to one week";
            case "5":
                return "Less than one day";
            case "6":
                return "Less than one hour";
            default:
                return false;
        }
    }

    const deployfreqValue = (values) => {
        switch (values.deployfreq) {
            case "1":
                return "Fewer than once per six months";
            case "2":
                return "Between once per month and once every six months";
            case "3":
                return "Between once per week and once per month";
            case "4":
                return "Between once per day and once per week";
            case "5":
                return "Between once per hour and once per day";
            case "6":
                return "On demand (multiple deploys per day)";
            default:
                return false;
        }
    }

    const chgfailValue = (values) => {
        switch (values.chgfail) {
            case "1":
                return "0–15%";
            case "2":
                return "16–30%";
            case "3":
                return "31–45%";
            case "4":
                return "46–60%";
            case "5":
                return "61–75%";
            case "6":
                return "76–100%";
            default:
                return false;
        }
    }

    const ttrValue = (values) => {
        switch (values.ttr) {
            case "1":
                return "More than six months";
            case "2":
                return "One to six months";
            case "3":
                return "One week to one month";
            case "4":
                return "One day to one week";
            case "5":
                return "Less than one day";
            case "6":
                return "Less than one hour";
            default:
                return false;
        }
    }

    const submitHubspotForm = async (values) => {
        let hasError = false;
        const config = {
            // important!
            headers: {
                "Content-Type": "application/json",
            },
        }

        const leadtime = leadtimeValue(values);
        const deployfreq = deployfreqValue(values);
        const chgfail = chgfailValue(values);
        const ttr = ttrValue(values);

        let result = getProfileAndPercentile(constants[2022], values);
        // console.log(result);

        setError(false);
        setErrorLeadTime(false);
        setErrorDeploy(false);
        setErrorChangeFail(false);
        setErrorTTR(false);

        if (leadtime == false) {
            hasError = true;
            setError(true);
            setErrorLeadTime(true);
        }

        if (deployfreq == false) {
            hasError = true;
            setError(true);
            setErrorDeploy(true);
        }

        if (chgfail == false) {
            hasError = true;
            setError(true);
            setErrorChangeFail(true);
        }

        if (ttr == false) {
            hasError = true;
            setError(true);
            setErrorTTR(true);
        }

        if (!hasError) {

            const hubspotFormValues = [
                {
                    name: "firstname",
                    value: values.firstname,
                },
                {
                    name: "email",
                    value: values.email,
                },
                {
                    name: "phone",
                    value: values.phone,
                },
                {
                    name: "change_time",
                    value: leadtime
                },
                {
                    name: "deploy_frequency",
                    value: deployfreq
                },
                {
                    name: "change_failure_rate",
                    value: chgfail
                },
                {
                    name: "time_to_restore",
                    value: ttr
                },
                {
                    name: "area",
                    value: values.industry,
                },
                {
                    name: "quickcheck_result",
                    value: `You scored ${result.percentile}%`,
                }
            ]

            // console.log(hubspotFormValues);

            if (!hasError) {
                setPercentage(result.percentile);
            } else {
                setPercentage(0);
            }

            const axiosRequest = await axios.post(
                `${process.env.HOOLIGAN_API_GATEWAY}/hubspot`,
                {
                    portalId: "25395670",
                    formGuid: "9f61a08e-5170-47f2-abd4-388aab991d4c",
                    name: "Devops - Quick check",
                    fields: [...hubspotFormValues],
                    context: {
                        pageName: windowCheck() && window.location.pathname,
                        pageUri: windowCheck() && window.location.href,
                    },
                },
                config
            )
        }
    }

    const indicators = {
        'leadtime': {
            'label': 'Lead time',
            'ticks': [
                { v: 0, f: '>6m' }, { v: 20, f: '1-6m' }, { v: 40, f: '1w - 1m' },
                { v: 60, f: '1d - 1w' }, { v: 80, f: '<1d' }, { v: 100, f: '<1h' }
            ]
        },
        'deployfreq': {
            'label': 'Deploy frequency',
            'ticks': [
                { v: 0, f: '>6m' }, { v: 20, f: '1-6m' }, { v: 40, f: '1w - 1m' },
                { v: 60, f: '1d - 1w' }, { v: 80, f: '<1d' }, { v: 100, f: 'on demand' }
            ]
        },
        'ttr': {
            'label': 'Time to restore',
            'ticks': [
                { v: 0, f: '>6m' }, { v: 20, f: '1-6m' }, { v: 40, f: '1w - 1m' },
                { v: 60, f: '1d - 1w' }, { v: 80, f: '<1d' }, { v: 100, f: '<1h' }
            ]
        },
        'chgfail': {
            'label': 'Change fail rate',
            'ticks': [
                { v: 0, f: '76-100%' }, { v: 20, f: '61-75%' }, { v: 40, f: '46-60%' },
                { v: 60, f: '31-45%' }, { v: 80, f: '16-30%' }, { v: 100, f: '0-15%' }
            ]
        }
    };

    const constants = {
        '2022': {
            'mean': 3.8187,
            'stddev': 1.0642,
            'baselines': {
                'all': { 'leadtime': 3.24, 'deployfreq': 3.3, 'ttr': 4.21, 'chgfail': 4.53 },
                'education': { 'leadtime': 3.14, 'deployfreq': 3.05, 'ttr': 4.21, 'chgfail': 4.75 },
                'energy': { 'leadtime': 3.24, 'deployfreq': 3.29, 'ttr': 4.19, 'chgfail': 4.65 },
                'finance': { 'leadtime': 3.38, 'deployfreq': 3.49, 'ttr': 4.6, 'chgfail': 4.95 },
                'government': { 'leadtime': 3.22, 'deployfreq': 3.09, 'ttr': 4.26, 'chgfail': 5.23 },
                'healthcare': { 'leadtime': 3.15, 'deployfreq': 3.22, 'ttr': 4.19, 'chgfail': 4.55 },
                'industrials': { 'leadtime': 3, 'deployfreq': 3.1, 'ttr': 4.12, 'chgfail': 4.53 },
                'insurance': { 'leadtime': 3.08, 'deployfreq': 2.79, 'ttr': 3.71, 'chgfail': 3.97 },
                'media': { 'leadtime': 3.18, 'deployfreq': 3.64, 'ttr': 3.82, 'chgfail': 4.56 },
                'nonprofit': { 'leadtime': 3.13, 'deployfreq': 3.23, 'ttr': 3.3, 'chgfail': 4.22 },
                'other': { 'leadtime': 3.46, 'deployfreq': 3.61, 'ttr': 4.53, 'chgfail': 5.08 },
                'retail': { 'leadtime': 3.48, 'deployfreq': 3.62, 'ttr': 4.74, 'chgfail': 4.86 },
                'technology': { 'leadtime': 3.22, 'deployfreq': 3.25, 'ttr': 4.09, 'chgfail': 4.29 },
                'telecoms': { 'leadtime': 2.9, 'deployfreq': 2.94, 'ttr': 4.02, 'chgfail': 4.34 }
            }
        },
        '2021': {
            'mean': 3.9495,
            'stddev': 1.0547,

            'baselines': {
                'all': { 'leadtime': 3.27, 'deployfreq': 3.33, 'ttr': 4.28, 'chgfail': 4.9 },
                'education': { 'leadtime': 2.81, 'deployfreq': 3.08, 'ttr': 3.62, 'chgfail': 4.92 },
                'energy': { 'leadtime': 2.77, 'deployfreq': 2.83, 'ttr': 3.68, 'chgfail': 4.6 },
                'finance': { 'leadtime': 3.15, 'deployfreq': 3.22, 'ttr': 4.25, 'chgfail': 4.92 },
                'government': { 'leadtime': 3.04, 'deployfreq': 3.06, 'ttr': 4.51, 'chgfail': 4.94 },
                'healthcare': { 'leadtime': 3.41, 'deployfreq': 3.5, 'ttr': 4.56, 'chgfail': 5.1 },
                'industrials': { 'leadtime': 2.76, 'deployfreq': 2.9, 'ttr': 3.92, 'chgfail': 4.68 },
                'insurance': { 'leadtime': 3.17, 'deployfreq': 2.98, 'ttr': 4.1, 'chgfail': 4.89 },
                'media': { 'leadtime': 3.88, 'deployfreq': 4.1, 'ttr': 4.7, 'chgfail': 4.85 },
                'nonprofit': { 'leadtime': 3.27, 'deployfreq': 3.52, 'ttr': 4.19, 'chgfail': 4.87 },
                'other': { 'leadtime': 2.94, 'deployfreq': 3.2, 'ttr': 4.24, 'chgfail': 4.95 },
                'retail': { 'leadtime': 3.48, 'deployfreq': 3.67, 'ttr': 4.26, 'chgfail': 4.74 },
                'technology': { 'leadtime': 3.55, 'deployfreq': 3.44, 'ttr': 4.46, 'chgfail': 4.94 },
                'telecoms': { 'leadtime': 3.04, 'deployfreq': 3.21, 'ttr': 4.17, 'chgfail': 5.18 }
            }
        }
    }

    function clamp(value, max) {
        return Math.max(Math.min(value, max), 1);
    }

    function scalePerf(value) {
        return 20 * (value - 1);
    }

    function normDist(x, mean, standardDeviation) {
        const a1 = 0.254829592;
        const a2 = -0.284496736;
        const a3 = 1.421413741;
        const a4 = -1.453152027;
        const a5 = 1.061405429;
        const p = 0.3275911;

        let val = (mean - x) / (Math.sqrt(2) * standardDeviation);

        let sign = (val < 0) ? -1 : 1;
        var xabs = Math.abs(val);
        var t = 1.0 / (1.0 + xabs * p);
        var y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-xabs * xabs);
        var erf = sign * y;
        return (1 - erf) / 2;
    }

    function getProfileAndPercentile(constants, userPerformanceIndicators) {

        let average = 0;

        Object.keys(indicators).forEach(indicator => {

            let indicator_value = userPerformanceIndicators[indicator];

            let unnormalized = clamp(parseInt(indicator_value), 6);
            average += unnormalized;

        })

        average = average / Object.keys(indicators).length;

        let percentile = Math.round(100 * normDist(average, constants.mean, constants.stddev));

        // TODO: #42 this doesn't have to be a struct since it's returning a single value
        return {
            percentile: percentile
        }

    }


    const { contentSections, metadata, localizations, slug } = data.strapiPage
    const global = data.strapiGlobal
    const navbar = data.strapiNavbar
    const footer = data.strapiFooter

    return (
        <>
            <Seo seo={metadata} global={global} />
            <Layout
                navbar={navbar}
                footer={footer}
                pageContext={{ ...pageContext, localizations, slug }}
            >
                <Sections sections={contentSections} />
                <form onSubmit={formik.handleSubmit}>

                    <div class="question__container mx-auto py-12 px-4">
                        <div
                            className={`question ${errorLeadTime && 'question--error'}`}>
                            <div
                                className="question__overlay"
                                style={
                                    { background: "linear-gradient(274.21deg, rgba(71, 215, 180, 0.8) 0%, rgba(8, 45, 101, 0.8) 98.48%)" }
                                }
                            ></div>
                            <div
                                className="question__inner"
                            >
                                <StylisedText data={[
                                    {
                                        id: 68,
                                        text: "<",
                                        style: "bold",
                                        colour: "green",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 69,
                                        text: "Lead ",
                                        style: "italic",
                                        colour: "orange",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 70,
                                        text: "time",
                                        style: "bold",
                                        colour: "black",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 70,
                                        text: "/>",
                                        style: "bold",
                                        colour: "black",
                                        colourOverride: null,
                                    }]} type="h5" />
                                <br></br>
                                <div className="question__description mb-8">
                                    For the primary application or service you work on, what is your lead time for changes (that is, how long does it take to go from code committed to code successfully running in production)?
                                </div>
                                <FormControl error={errorLeadTime}>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="leadtime"
                                    >
                                        <FormControlLabel value="1" style={{ color: "white" }} control={<Radio value="1" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="More than six months" />
                                        <FormControlLabel value="2" style={{ color: "white" }} control={<Radio value="2" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="One to six months" />
                                        <FormControlLabel value="3" style={{ color: "white" }} control={<Radio value="3" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="One week to one month" />
                                        <FormControlLabel value="4" style={{ color: "white" }} control={<Radio value="4" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="One day to one week" />
                                        <FormControlLabel value="5" style={{ color: "white" }} control={<Radio value="5" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="Less than one day" />
                                        <FormControlLabel value="6" style={{ color: "white" }} control={<Radio value="6" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="Less than one hour" />
                                    </RadioGroup>
                                    {error && <FormHelperText>{helperText}</FormHelperText>}
                                </FormControl>
                            </div>
                        </div>

                        <div
                            className={`question ${errorDeploy && 'question--error'}`}>
                            <div
                                className="question__overlay"
                                style={
                                    { background: "linear-gradient(274.21deg, rgba(71, 215, 180, 0.8) 0%, rgba(8, 45, 101, 0.8) 98.48%)" }
                                }
                            ></div>
                            <div
                                className="question__inner"
                            >
                                <StylisedText data={[
                                    {
                                        id: 68,
                                        text: "<",
                                        style: "bold",
                                        colour: "green",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 69,
                                        text: "Deploy ",
                                        style: "italic",
                                        colour: "orange",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 70,
                                        text: "frequency",
                                        style: "bold",
                                        colour: "black",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 70,
                                        text: "/>",
                                        style: "bold",
                                        colour: "black",
                                        colourOverride: null,
                                    }]} type="h5" />
                                <br></br>
                                <div className="question__description mb-8">
                                    For the primary application or service you work on, how often does your organization deploy code to production or release it to end users?
                                </div>
                                <FormControl error={errorDeploy}>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="deployfreq"
                                    >
                                        <FormControlLabel value="1" style={{ color: "white" }} control={<Radio value="1" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="Fewer than once per six months" />
                                        <FormControlLabel value="2" style={{ color: "white" }} control={<Radio value="2" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="Between once per month and once every six months" />
                                        <FormControlLabel value="3" style={{ color: "white" }} control={<Radio value="3" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="Between once per week and once per month" />
                                        <FormControlLabel value="4" style={{ color: "white" }} control={<Radio value="4" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="Between once per day and once per week" />
                                        <FormControlLabel value="5" style={{ color: "white" }} control={<Radio value="5" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="Between once per hour and once per day" />
                                        <FormControlLabel value="6" style={{ color: "white" }} control={<Radio value="6" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="On demand (multiple deploys per day)" />
                                    </RadioGroup>
                                    {error && <FormHelperText>{helperText}</FormHelperText>}
                                </FormControl>
                            </div>
                        </div>

                        <div
                            className={`question ${errorChangeFail && 'question--error'}`}>
                            <div
                                className="question__overlay"
                                style={
                                    { background: "linear-gradient(274.21deg, rgba(71, 215, 180, 0.8) 0%, rgba(8, 45, 101, 0.8) 98.48%)" }
                                }
                            ></div>
                            <div
                                className="question__inner"
                            >
                                <StylisedText data={[
                                    {
                                        id: 68,
                                        text: "<",
                                        style: "bold",
                                        colour: "green",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 69,
                                        text: "Change failure ",
                                        style: "italic",
                                        colour: "orange",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 70,
                                        text: "percentage",
                                        style: "bold",
                                        colour: "black",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 70,
                                        text: "/>",
                                        style: "bold",
                                        colour: "black",
                                        colourOverride: null,
                                    }]} type="h5" />
                                <br></br>
                                <div className="question__description mb-8">
                                    For the primary application or service you work on, what percentage of changes to production or releases to users result in degraded service (for example, lead to service impairment or service outage) and subsequently require remediation (for example, require a hotfix, rollback, fix forward, patch)?
                                </div>
                                <FormControl error={errorChangeFail}>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="chgfail"
                                    >
                                        <FormControlLabel value="1" style={{ color: "white" }} control={<Radio value="1" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="0–15%" />
                                        <FormControlLabel value="2" style={{ color: "white" }} control={<Radio value="2" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="16–30%" />
                                        <FormControlLabel value="3" style={{ color: "white" }} control={<Radio value="3" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="31–45%" />
                                        <FormControlLabel value="4" style={{ color: "white" }} control={<Radio value="4" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="46–60%" />
                                        <FormControlLabel value="5" style={{ color: "white" }} control={<Radio value="5" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="61–75%" />
                                        <FormControlLabel value="6" style={{ color: "white" }} control={<Radio value="6" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="76–100%" />
                                    </RadioGroup>
                                    {error && <FormHelperText>{helperText}</FormHelperText>}
                                </FormControl>
                            </div>
                        </div>

                        <div
                            className={`question ${errorTTR && 'question--error'}`}>
                            <div
                                className="question__overlay"
                                style={
                                    { background: "linear-gradient(274.21deg, rgba(71, 215, 180, 0.8) 0%, rgba(8, 45, 101, 0.8) 98.48%)" }
                                }
                            ></div>
                            <div
                                className="question__inner"
                            >
                                <StylisedText data={[
                                    {
                                        id: 68,
                                        text: "<",
                                        style: "bold",
                                        colour: "green",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 69,
                                        text: "Time to ",
                                        style: "italic",
                                        colour: "orange",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 70,
                                        text: "restore",
                                        style: "bold",
                                        colour: "black",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 70,
                                        text: "/>",
                                        style: "bold",
                                        colour: "black",
                                        colourOverride: null,
                                    }]} type="h5" />
                                <br></br>
                                <div className="question__description mb-8">
                                    For the primary application or service you work on, how long does it generally take to restore service when a service incident or a defect that impacts users occurs (for example, unplanned outage, service impairment)?
                                </div>
                                <FormControl error={errorTTR}>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue=""
                                        name="ttr"

                                    >
                                        <FormControlLabel value="1" style={{ color: "white" }} control={<Radio value="1" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="More than six months" />
                                        <FormControlLabel value="2" style={{ color: "white" }} control={<Radio value="2" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="One to six months" />
                                        <FormControlLabel value="3" style={{ color: "white" }} control={<Radio value="3" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="One week to one month" />
                                        <FormControlLabel value="4" style={{ color: "white" }} control={<Radio value="4" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="One day to one week" />
                                        <FormControlLabel value="5" style={{ color: "white" }} control={<Radio value="5" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="Less than one day" />
                                        <FormControlLabel value="6" style={{ color: "white" }} control={<Radio value="6" style={{ color: "white" }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur} />} label="Less than one hour" />
                                    </RadioGroup>
                                    {error && <FormHelperText>{helperText}</FormHelperText>}
                                </FormControl>
                            </div>
                        </div>

                        <div
                            className="question">
                            <div
                                className="question__overlay"
                                style={
                                    { background: "linear-gradient(274.21deg, rgba(71, 215, 180, 0.8) 0%, rgba(8, 45, 101, 0.8) 98.48%)" }
                                }
                            ></div>
                            <div
                                className="question__inner"
                            >
                                <StylisedText data={[
                                    {
                                        id: 68,
                                        text: "<",
                                        style: "bold",
                                        colour: "green",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 69,
                                        text: "Industry",
                                        style: "italic",
                                        colour: "orange",
                                        colourOverride: null,
                                    },
                                    {
                                        id: 70,
                                        text: "/>",
                                        style: "bold",
                                        colour: "black",
                                        colourOverride: null,
                                    }]} type="h5" />
                                <br></br>
                                <div className="question__description mb-8">
                                    What is the principal industry of your organization?
                                </div>
                                <FormControl>
                                    <Select
                                        labelId="industry-select-label"
                                        id="industry-select"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        labelStyle={{ color: '#fff' }}
                                        placeholder="Industry"
                                        name="industry"
                                        sx={{
                                            color: "white",
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(228, 219, 233, 1)',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(228, 219, 233, 1)',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(228, 219, 233, 1)',
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: "white !important",
                                            }
                                        }}
                                    >
                                        <MenuItem value="education">Education</MenuItem>
                                        <MenuItem value="energy">Energy</MenuItem>
                                        <MenuItem value="finance">Financial Services</MenuItem>
                                        <MenuItem value="government">Government</MenuItem>
                                        <MenuItem value="healthcare">Healthcare & Pharmaceuticals</MenuItem>
                                        <MenuItem value="industrials">Industrials & Manufacturing</MenuItem>
                                        <MenuItem value="insurance">Insurance</MenuItem>
                                        <MenuItem value="media">Media/Entertainment</MenuItem>
                                        <MenuItem value="nonprofit">Non-profit</MenuItem>
                                        <MenuItem value="retail">Retail/Consumer/e-Commerce</MenuItem>
                                        <MenuItem value="technology">Technology</MenuItem>
                                        <MenuItem value="telecoms">Telecommunications</MenuItem>
                                        <MenuItem value="other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                        </div>
                        <div className="flex flex-row justify-center text-center">

                            <Typography variant="h5" component="h5" gutterBottom>Complete the below form to see your results compared to other leading software companies</Typography>
                        </div>
                    </div>
                    <section className={"getInTouch standard"} style={{ minHeight: 'unset' }}>
                        <div className="getInTouch__container">
                            <div className="getInTouch__textwrapper">
                                <BreadcrumbTitle data={{
                                    "id": 4,
                                    "title": "Get your results",
                                    "colour": "lightgrey",
                                    "colourOverride": null,
                                    "dashColour": "orange"
                                }} />
                                <StylisedText data={[
                                    {
                                        "id": 68,
                                        "text": "<",
                                        "style": "bold",
                                        "colour": "green",
                                        "colourOverride": null
                                    },
                                    {
                                        "id": 70,
                                        "text": "How you do?",
                                        "style": "bold",
                                        "colour": "white",
                                        "colourOverride": null
                                    },
                                    {
                                        "id": 71,
                                        "text": "/>",
                                        "style": "bold",
                                        "colour": "green",
                                        "colourOverride": null
                                    }
                                ]} type="h3" />
                                <p className="getInTouch__text">Whether you’re ready to hire a Hooligan or just have a problem or project you want to discuss, drop us your details so we can get in touch.</p>
                            </div>
                            <div className="getInTouch__formwrapper">
                                <div className="formComponent">
                                    <div
                                        className="formComponent__formWrapper"
                                    >
                                        <input
                                            placeholder={"Your Name*"}
                                            name={"firstname"}
                                            type={"text"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required={true}
                                        />
                                        <input
                                            placeholder={"Email address*"}
                                            name={"email"}
                                            type={"email"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required={true}
                                        />
                                        <input
                                            placeholder={"Your Phone number*"}
                                            name={"phone"}
                                            type={"text"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required={true}
                                        />

                                        {error && <Typography variant="p" component="p" style={{ color: 'red' }} gutterBottom>Not all fields were selected, please anser all questions to see your results.</Typography>}

                                        { percentage == 0 && <div className="formComponent__buttonWrapper">
                                            <button
                                                aria-label="Submit"
                                                type="submit"
                                                className="button-link button-link--primary"
                                            >
                                                VIEW RESULTS
                                            </button>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                percentage > 0 && <div className="question__container mx-auto py-12 md:px-4">
                                    <div className="flex flex-col items-center w-full text-center">
                                        <Typography className="text-white" variant="h4" component="h4" gutterBottom>Your software delivery performance</Typography>
                                        <div className="flex flex-row justify-center items-center  w-full">
                                            <div className="score">
                                                {percentage}
                                            </div>
                                            <div className="performance">
                                                <div className="level" style={{ left: `${percentage}%` }}></div>
                                                <div class="tickmarks">
                                                    <div class="tick" style={{ left: "0%", color: 'white' }}>
                                                        0
                                                    </div>
                                                    <div class="tick" style={{ left: "25%", color: 'white' }}>
                                                        25
                                                    </div>
                                                    <div class="tick" style={{ left: "50%", color: 'white' }}>
                                                        50
                                                    </div>
                                                    <div class="tick" style={{ left: "75%", color: 'white' }}>
                                                        75
                                                    </div>
                                                    <div class="tick" style={{ left: "98%", color: 'white' }}>
                                                        100
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="block m-4">
                                            <Typography className="text-white" variant="h5" component="h5" gutterBottom>You scored {percentage}%, we will reach out to see how we can make it 110%</Typography>
                                            <br></br>
                                            <Typography className="text-white" variant="p" component="p" gutterBottom>In our experience, we have identified several key capabilities which drive higher software delivery and organizational performance. Improving these technical, process, and cultural capabilities can help your team deliver more value to your customers and organization. It's important to focus your efforts on the specific thing that is currently holding you back. While every team will take a different journey, we have identified three capabilities that are often beneficial to improve: Continuous Integration, Loosely Coupled Architecture, and Generative Organizational Culture.</Typography>
                                        </div>
                                    </div>


                                </div>
                            }
                        </div>
                    </section>
                </form>
            </Layout >
        </>
    )
}

export default DynamicPage

export const query = graphql`
  fragment GlobalData on StrapiGlobal {
    favicon {
      localFile {
        publicURL
      }
    }
    id
    metaTitleSuffix
    metadata {
      metaDescription
      metaTitle
      metaKeywords
      shareImage {
        localFile {
          publicURL
        }
      }
    }
  }

  fragment NavbarData on StrapiNavbar {
    button {
      id
      newTab
      text
      type
      url
    }
    id
    links {
      url
      text
      newTab
      id
    }
  }

  fragment FooterData on StrapiFooter {
    id
    columns {
      id
      links {
        id
        newTab
        text
        url
      }
      title
    }
    id
    smallText
    email
    policies {
      id
      newTab
      text
      pdf {
        url
      }
    }
  }

  query DynamicDevopsQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiNavbar {
      ...NavbarData
    }
    strapiFooter {
      ...FooterData
    }
    strapiPage(id: { eq: $id }) {
      slug
      shortName
      metadata {
        metaTitle
        metaDescription
        metaKeywords
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      contentSections
    }
  }
`
