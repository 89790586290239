import React, { useEffect, useState } from "react"
import StylisedText from "../elements/stylised-text"
import BreadcrumbTitle from "../elements/breadcrumb-title"
import { graphql, useStaticQuery } from "gatsby"
import axios from "axios"
import FormComponent from "../elements/forms/form-component"
import classNames from "classnames"
import LinkChevron from "../../images/link-chevron.svg"

const QuickCheckForm = ({ data }) => {
    // console.log(data);
    const [formData, setFormData] = useState()
    const [formValues, setFormValues] = useState()

    const pageQueryData = useStaticQuery(QuickCheckFormQuery)

    const { edges } = pageQueryData.allHubspotForm
    // console.log(edges)

    const windowCheck = () => {
        return typeof window !== `undefined` ? true : false
    }

    useEffect(() => {
        // data = {}
        data.formGuid = "9f61a08e-5170-47f2-abd4-388aab991d4c";

        let edgeForm = edges.find(edge => { return edge.node.guid === data.formGuid });
        if (edgeForm == null) {
            edgeForm = edges.find(edge => { return edge.node.guid === "9f61a08e-5170-47f2-abd4-388aab991d4c" });
        }

        setFormData(edgeForm.node)
        let newData = []
        const getValue = field => {
            return field?.options[0]?.label || ""
        }
        formData?.formFieldGroups?.map(value => (
            newData = [
                ...newData,
                { name: value.fields[0].name, value: getValue(value.fields[0]) },
            ]
        ))
        setFormValues(newData) // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let newData = []
        const getValue = field => {
            return ""
        }
        formData?.formFieldGroups?.map(value => (
            newData = [
                ...newData,
                { name: value.fields[0].name, value: getValue(value.fields[0]) },
            ]
        ))
        // console.log(newData)
        setFormValues(newData)
    }, [formData])


    const submitHubspotForm = async () => {
        const config = {
            // important!
            headers: {
                "Content-Type": "application/json",
            },
        }

        // console.log([...formValues]);

        for (const obj of formValues) {
            // console.log(obj.name)
            if (obj.name === "leadtime") {
                obj.value = data.values.leadtime;

                break
            }
            if (obj.name === "deploy_frequency") {
                obj.value = data.values.deployfreq;

                break
            }
            if (obj.name === "change_failure_rate") {
                obj.value = data.values.chgfail;

                break
            }
            if (obj.name === "time_to_restore") {
                obj.value = data.values.ttr;

                break
            }
            if (obj.name === "industry") {
                obj.value = data.values.industry;

                break
            }
        }

        // console.log([...formValues]);
        const axiosRequest = await axios.post(
            `${process.env.HOOLIGAN_API_GATEWAY}/hubspot`,
            {
                portalId: "25395670",
                formGuid: "9f61a08e-5170-47f2-abd4-388aab991d4c",
                name: formData.name,
                fields: [...formValues],
                context: {
                    pageName: windowCheck() && window.location.pathname,
                    pageUri: windowCheck() && window.location.href,
                },
            },
            config
        )

        return axiosRequest
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const hubspotResponse = await submitHubspotForm()
        return hubspotResponse
    }

    const handleInputChange = (e, fieldData) => {
        // console.log(e.target.value);
        // console.log(formValues)
    }

    return (
        <section className={classNames("getInTouch", "standard")}>
            <div className="getInTouch__container">
                <div className="getInTouch__textwrapper">
                    <BreadcrumbTitle data={{
                        "id": 4,
                        "title": "Get your results",
                        "colour": "lightgrey",
                        "colourOverride": null,
                        "dashColour": "orange"
                    }} />
                    <StylisedText data={[
                        {
                            "id": 68,
                            "text": "<",
                            "style": "bold",
                            "colour": "green",
                            "colourOverride": null
                        },
                        {
                            "id": 70,
                            "text": "How you do?",
                            "style": "bold",
                            "colour": "white",
                            "colourOverride": null
                        },
                        {
                            "id": 71,
                            "text": "/>",
                            "style": "bold",
                            "colour": "green",
                            "colourOverride": null
                        }
                    ]} type="h3" />
                    <p className="getInTouch__text">Whether you’re ready to hire a Hooligan or just have a problem or project you want to discuss, drop us your details so we can get in touch.</p>
                </div>
                <div className="getInTouch__formwrapper">
                    <div className="formComponent">
                        <form
                            onSubmit={handleSubmit}
                            className="formComponent__formWrapper"
                        >
                            <input
                                placeholder={"Your Name*"}
                                name={"firstname"}
                                type={"text"}
                                onChange={e => handleInputChange(e)}
                                required={true}
                            />
                            <input
                                placeholder={"Email address*"}
                                name={"email"}
                                type={"email"}
                                onChange={e => handleInputChange(e)}
                                required={true}
                            />
                            <input
                                placeholder={"Your Phone number*"}
                                name={"phone"}
                                type={"text"}
                                onChange={e => handleInputChange(e)}
                                required={true}
                            />

                            <div className="formComponent__buttonWrapper">
                                <button
                                    aria-label="Submit"
                                    type="submit"
                                    className="button-link button-link--primary"
                                >
                                    Submit <LinkChevron className="icon" />
                                </button>
                            </div>
                        </form>
                    </div>
                    {/* <FormComponent
                        submitForm={handleSubmit}
                        formFieldGroups={[
                            {
                                "fields": [
                                    {
                                        "label": null,
                                        "name": "firstname",
                                        "required": true,
                                        "fieldType": "single_line_text",
                                        "hidden": false,
                                        "placeholder": "Your Name*",
                                        "options": null
                                    }
                                ]
                            },
                            {
                                "fields": [
                                    {
                                        "label": null,
                                        "name": "email",
                                        "required": true,
                                        "fieldType": "email",
                                        "hidden": false,
                                        "placeholder": "Your Company Mail*",
                                        "options": null
                                    }
                                ]
                            },
                            {
                                "fields": [
                                    {
                                        "label": "Phone number",
                                        "name": "phone",
                                        "required": true,
                                        "fieldType": "single_line_text",
                                        "hidden": false,
                                        "placeholder": "Your Contact Number*",
                                        "options": null
                                    }
                                ]
                            }
                        ]}
                        handleInputChange={handleInputChange}
                        formType="contact-us"
                    /> */}
                </div>
            </div>
        </section>
    )
}

export default QuickCheckForm

const QuickCheckFormQuery = graphql`
  query QuickCheckFormQuery {
    allHubspotForm(limit: 10) {
      edges {
        node {
          portalId
          guid
          name
          formFieldGroups {
            fields {
              label
              name
              required
              fieldType
              hidden
              placeholder
              options {
                label
                value
              }
            }
          }
        }
      }
    }
  }
`
